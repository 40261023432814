<template>
	<div id="rewards">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">奖惩管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
				
			</div>
			<div class="content">
				
				
				<div class="suguan" v-if="teacherIndex==2">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
						</div>
						<div class="u-f-item">
							<el-date-picker clearable value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="time" @change="timeChange" type="daterange" size="small" range-separator="至" style="margin-right: 10px;" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
							<el-button size='small' type="primary" @click="rewardsImport">导出</el-button>
						</div>
					</div>
					<el-table
						:data="list"
						height="94%"
						style="width: 100%;border: 1px solid #EEEEEE;"
					>
						<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
						<el-table-column label="宿舍" align="center"  width="100" prop="dorm.dorm"></el-table-column>
						<el-table-column prop="title" label="奖惩项" align="center"></el-table-column>
						<el-table-column label="奖惩分值" width="100" prop="score" align="center"></el-table-column>
						<el-table-column label="图片" align="center">
							<template  slot-scope="scope">
								<el-image style="width: 50px; height: 50px":src="scope.row.image" fit="cover" :preview-src-list="[scope.row.image]">
									<div slot="error" class="u-f-justify" style="width: 100%;height: 100%;background: #F5F7FA;">
									    <i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column label="备注" align="center">
							<template  slot-scope="scope">
								{{scope.row.remarks}}
							</template>
						</el-table-column>
						<el-table-column label="创建时间" align="center">
							<template  slot-scope="scope">
								{{$common.timeToDate(scope.row.createtime)}}
							</template>
						</el-table-column>
						<el-table-column label="创建人" width="120" prop="admin_name" align="center"></el-table-column>
						<el-table-column label="操作" width="100" align="center">
							<template  slot-scope="scope">
								<el-button @click="edit_score(scope.row)" type="text" size="small">编辑分值</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			<el-dialog title="修改分值" :visible.sync="editShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							分数
						</div>
						<el-input-number v-model="score" :max="30" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitscore">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [
				{ name: '学生奖惩',id:1},
				{ name: '班级奖惩',id:2},
				{ name: '宿舍奖惩',id:3},
				{ name: '奖惩配置',id:4}
			],
			teacherIndex: 2,
			page:1,
			limit:12,
			total:0,
			list:[],
			time:[],
			id:'',
			score:0,
			editShow:false
		};
	},
	mounted() {
		this.rewardLists()
	},
	methods: {
		submitscore(){
			this.$api.setting.RewardsRecordModify({
				id:this.id,
				score:this.score
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功');
					this.rewardLists()
					this.editShow = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		handleClose(done){
			done()
		},
		edit_score(item){
			this.id = item.id;
			this.score = item.score;
			this.editShow = true;
		},
		timeChange(e){
			this.page = 1;
			this.rewardLists()
		},
		twacherTap(index){
			if(index==0){
				this.$router.push('/studentrewards')
			}else if(index==1){
				this.$router.push('/classRewards')
			}else if(index==2){
				this.$router.push('/dormrewards')
			}else if(index==3){
				this.$router.push('/rewards')
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.rewardLists()
		},
		rewardLists(){
			let data = {
				page:this.page,
				limit:this.limit,
				filter:JSON.stringify({'type':3})
			}
			if(this.time.length){
				data.start_date = this.time[0];
				data.end_date = this.time[1];
			}
			this.$api.setting.rewardLists(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		rewardsImport(){
			let data = {}
			if(this.time.length){
				data.start_date = this.time[0];
				data.end_date = this.time[1];
			}
			this.$api.setting.dormRewardsExport(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('导出成功');
					location.href = res.data.data;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#rewards {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan{
			padding: 15px 50px 15px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
